import { Environment } from '@core-hr/shared'

export const environment: Environment = {
  firebase: {
    projectId: 'baylaurel-development-2',
    appId: '1:602574687421:web:585e75f5d56bfd7b9ffbd4',
    storageBucket: 'baylaurel-development-2.appspot.com',
    locationId: 'asia-northeast1',
    apiKey: 'AIzaSyB4zZrs4gXZa8htXuVuWlNERz79ikYC4Jo',
    authDomain: 'baylaurel-development-2.firebaseapp.com',
    messagingSenderId: '602574687421',
    measurementId: '',
  },
  production: false,
  useEmulators: false,
  env: 'development-2',
  moduleFederationManifestPath: '/assets/module-federation.manifest.dev2.json',
  useChat: false,
  chatCode: '',
}
